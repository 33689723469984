<template>
  <div class="manual-view">
    <section class="title-area">
      <h1>使い方マニュアル</h1>
      <i class="icon icon-close" @click="$emit('closeManualOverlay')"></i>
    </section>

    <section class="basic-operation-area">
      <div class="basic-operation-title" @click="basicOperationOpenToggle()">
        <h3>基本操作</h3>
        <i class="icon icon-arrow_drop_down" :class="{ active: basicOperationOpen }"></i>
      </div>
      <div class="basic-operation-accordion" v-show="basicOperationOpen">
        <ul>
          <li>サイドバーから閲覧したいメニューを選択します。</li>
          <li>アーティストに紐づくデータと一般統計データがあります。</li>
          <li>データは期間で絞り込みができます。</li>
          <li>アーティスト名や楽曲名で絞り込みできます。</li>
          <li>ライブ会場やイベント名で絞り込みができます。</li>
          <li>アーティストによって取得できているデータが異なります。</li>
        </ul>
      </div>
    </section>

    <section class="search-wrapper">
      <p>索引からキーワードで検索</p>
      <div class="input-wrapper">
        <span class="icon-search"></span>
        <input type="search" v-model="filterString" />
      </div>
    </section>

    <section class="manual-area" id="manual-area"><ManualOverlayAccordionItem :manual-filter="manualFilter" /></section>
  </div>
</template>

<script>
import ManualOverlayAccordionItem from '@/components/ManualOverlayAccordionItem.vue';

export default {
  name: 'ManualOverlay',
  components: {
    ManualOverlayAccordionItem,
  },
  props: {
    manualJson: { default: () => {} },
  },
  data() {
    return {
      filterString: '',
      manualFilter: [],
      basicOperationOpen: false,
    };
  },
  async created() {},
  computed: {},
  methods: {
    basicOperationOpenToggle() {
      this.basicOperationOpen = !this.basicOperationOpen;
    },
  },
  async mounted() {},
  watch: {
    manualJson: function (v) {
      this.manualFilter = v;
    },
    filterString: function (v) {
      if (v) {
        this.manualFilter = this.manualJson
          .map((e) => {
            if (e.category_title.indexOf(v) !== -1) return e;
            const items = e.items.filter((f) => f.how_to.indexOf(v) !== -1 || f.supplement.indexOf(v) !== -1);
            if (Object.keys(items).length) return { category_title: e.category_title, items: items };
          })
          .filter((e) => e !== undefined);
      } else {
        this.manualFilter = this.manualJson;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.manual-view {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 1400;
  height: -webkit-fill-available;
  width: 800px;
  right: 0;
  background-color: var(--background-color);
  box-shadow: -6px 0px 15px 0px rgba(0, 0, 0, 0.3);
  .title-area {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 104px;
    padding: 32px;
    color: white;
    background-color: var(--primary-color);
    h1 {
      letter-spacing: 5px;
    }
    .icon-close {
      position: absolute;
      font-size: 24px;
      top: 12px;
      right: 12px;
      padding: 4px;
      cursor: pointer;
    }
  }
  .basic-operation-area {
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    .basic-operation-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 56px;
      background-color: white;
      cursor: pointer;
      h3 {
        font-size: 18px;
        letter-spacing: 5px;
      }
      .icon-arrow_drop_down {
        font-size: 32px;
        transition: transform 0.3s ease;
        &.active {
          transform: translateY(2px) rotate(180deg);
        }
      }
    }
    .basic-operation-accordion {
      padding: 16px 32px 16px 64px;
      background-color: var(--gray-05-color);
      ul li {
        display: list-item;
      }
    }
  }
  .search-wrapper {
    margin: 32px auto;
    p {
      font-size: 16px;
      margin-bottom: 16px;
    }
    .input-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      .icon-search {
        position: absolute;
        margin-top: -2px;
        margin-left: 16px;
        font-size: 20px;
        color: var(--gray-02-color);
      }
      input {
        padding: 0 16px 0 48px;
        font-size: var(--font-size-middle);
        line-height: 2.6;
        border: 1px solid var(--gray-03-color);
        border-radius: 4px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }
  }
  .manual-area {
    flex: 1;
    overflow-y: auto;
    padding: 8px 32px 16px;
    &::-webkit-scrollbar {
      width: 14px;
    }

    &::-webkit-scrollbar-track {
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--group02-2-color);
    }
  }
}
</style>
