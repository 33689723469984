import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'GROOVEFORCE ENGAGEMENT',
    },
  },
  {
    path: '/artist-analytics',
    name: 'ArtistAnalytics',
    component: () => import('../views/ArtistAnalytics.vue'),
    meta: {
      title: 'アーティスト分析 - アーティスト | GROOVEFORCE ENGAGEMENT',
    },
  },
  {
    path: '/artist-youtubecomments',
    name: 'ArtistYoutubeComments',
    component: () => import('../views/ArtistYoutubeComments.vue'),
    meta: {
      title: 'YouTubeコメント分析 - アーティスト | GROOVEFORCE ENGAGEMENT',
    },
  },
  {
    path: '/artist-tieup',
    name: 'ArtistTieup',
    component: () => import('../views/ArtistTieup.vue'),
    meta: {
      title: 'タイアップ分析 - アーティスト | GROOVEFORCE ENGAGEMENT',
    },
  },
  {
    path: '/purchase-analytics',
    name: 'PurchaseAnalytics',
    component: () => import('../views/PurchaseAnalytics.vue'),
    meta: {
      title: '購買分析 - アーティスト | GROOVEFORCE ENGAGEMENT',
    },
  },
  {
    path: '/artist-benchmark',
    name: 'ArtistBenchmark',
    component: () => import('../views/ArtistBenchmark.vue'),
    meta: {
      title: 'ベンチマーク比較 - アーティスト | GROOVEFORCE ENGAGEMENT',
    },
  },

  {
    path: '/sonygroup-analytics',
    name: 'SonyGroupAnalytics',
    component: () => import('../views/SonyGroupAnalytics.vue'),
    meta: {
      title: 'ソニーグループ分析 - サイト | GROOVEFORCE ENGAGEMENT',
    },
  },
  {
    path: '/site-analytics',
    name: 'SiteAnalytics',
    component: () => import('../views/SiteAnalytics.vue'),
    meta: {
      title: 'サイト分析 - サイト | GROOVEFORCE ENGAGEMENT',
    },
  },

  {
    path: '/fanclub-analytics',
    name: 'FanclubAnalytics',
    component: () => import('../views/FanclubAnalytics.vue'),
    meta: {
      title: 'ファンクラブ分析 - ファンビジネス | GROOVEFORCE ENGAGEMENT',
    },
  },
  {
    path: '/goods-analytics',
    name: 'GoodsAnalytics',
    component: () => import('../views/GoodsAnalytics.vue'),
    meta: {
      title: 'グッズ分析 - ファンビジネス | GROOVEFORCE ENGAGEMENT',
    },
  },
  {
    path: '/events-analytics',
    name: 'EventsAnalytics',
    component: () => import('../views/EventsAnalytics.vue'),
    meta: {
      title: 'ライブ・イベント分析 - ファンビジネス | GROOVEFORCE ENGAGEMENT',
    },
  },

  {
    path: '/live-hall-visitor-analytics',
    name: 'LiveHallVisitorAnalytics',
    component: () => import('../views/LiveHallVisitorAnalytics.vue'),
    meta: {
      title: '来場者分析 - ライブ会場 | GROOVEFORCE ENGAGEMENT',
    },
  },

  {
    path: '/trends-generationz',
    name: 'TrendsGenerationZ',
    component: () => import('../views/TrendsGenerationZ.vue'),
    meta: {
      title: 'Z世代トレンド調査 - アンケート | GROOVEFORCE ENGAGEMENT',
    },
  },
  {
    path: '/trends-anime',
    name: 'TrendsAnime',
    component: () => import('../views/TrendsAnime.vue'),
    meta: {
      title: 'アニメトレンド調査 - アンケート | GROOVEFORCE ENGAGEMENT',
    },
  },

  {
    path: '/crm-analytics',
    name: 'CrmAnalytics',
    component: () => import('../views/CrmAnalytics.vue'),
    meta: {
      title: 'CRM 分析 - 顧客データ | GROOVEFORCE ENGAGEMENT',
    },
  },

  {
    path: '/anime-analytics',
    name: 'AnimeAnalytics',
    component: () => import('../views/AnimeAnalytics.vue'),
    meta: {
      title: 'アニメ作品分析 - アニメ・ゲーム | GROOVEFORCE ENGAGEMENT',
    },
  },
  {
    path: '/game-analytics',
    name: 'GameAnalytics',
    component: () => import('../views/GameAnalytics.vue'),
    meta: {
      title: 'ゲーム作品分析 - アニメ・ゲーム | GROOVEFORCE ENGAGEMENT',
    },
  },
  {
    path: '/aniplex-analytics',
    name: 'AniplexAnalytics',
    component: () => import('../views/AniplexAnalytics.vue'),
    meta: {
      title: 'アニプレックス分析 - アニメ・ゲーム | GROOVEFORCE ENGAGEMENT',
    },
  },
  {
    path: '/anime-nextbreak-analytics',
    name: 'AnimeNextbreakAnalytics',
    component: () => import('../views/AnimeNextbreakAnalytics.vue'),
    meta: {
      title: 'ネクストブレイク分析 - アニメ・ゲーム | GROOVEFORCE ENGAGEMENT',
    },
  },

  {
    path: '/report-list',
    name: 'ReportList',
    component: () => import('../views/ReportList.vue'),
    meta: {
      title: 'レポート一覧 - その他 | GROOVEFORCE ENGAGEMENT',
    },
  },

  {
    path: '/manage-user',
    name: 'ManageUser',
    component: () => import('../views/Manage/ManageUser.vue'),
    meta: {
      title: 'ユーザー管理 - 管理機能 | GROOVEFORCE ENGAGEMENT',
    },
  },
  {
    path: '/manage-artist',
    name: 'ManageArtist',
    component: () => import('../views/Manage/ManageArtist.vue'),
    meta: {
      title: 'アーティスト管理 - 管理機能 | GROOVEFORCE ENGAGEMENT',
    },
  },
  {
    path: '/manage-report',
    name: 'ManageReport',
    component: () => import('../views/Manage/ManageReport.vue'),
    meta: {
      title: 'レポート管理 - 管理機能 | GROOVEFORCE ENGAGEMENT',
    },
  },
  {
    path: '/manage-news',
    name: 'ManageNews',
    component: () => import('../views/Manage/ManageNews.vue'),
    meta: {
      title: 'お知らせ管理 - 管理機能 | GROOVEFORCE ENGAGEMENT',
    },
  },
  {
    path: '/manage-access-log',
    name: 'ManageAccessLog',
    component: () => import('../views/Manage/ManageAccessLog.vue'),
    meta: {
      title: 'アクセスログ - 管理機能 | GROOVEFORCE ENGAGEMENT',
    },
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
