import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_AZURE_AD_CLIENT_ID,
    authority: process.env.VUE_APP_AZURE_AD_AUTHORITY,
    redirectUri: location.protocol + '//' + location.host,
  },
  cache: {
    cacheLocation: 'localStorage', // キャッシュの場所を指定します
    storeAuthStateInCookie: false, // 認証状態をCookieに保存しないようにします
    claimsBasedCachingEnabled: true, // クレームベースのキャッシュを有効にします (MSAL 3.x で追加されました)
  },
  system: {
    iframeHashTimeout: 10000,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          /*case LogLevel.Info:
            console.info(message);
            return;*/
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose,
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
export async function initializeMsal() {
  await msalInstance.initialize();
}
initializeMsal().catch((error) => {
  console.error('Failed to initialize msal', error);
});

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};
