<template>
  <div>
    <div class="change-password">
      <h1>パスワードの変更</h1>
      <h3 class="subTitle" v-if="subTitle">{{ subTitle }}</h3>
      <div class="password-request">
        <div class="password-request-left">
          <div>
            <input type="email" name="username" autocomplete="username" class="username" :value="username" />
            <div class="pass">現在のパスワード</div>
            <p>
              <input type="password" autocomplete="current-password" maxlength="256" ref="oldPassword" v-model="oldPassword" required /><i
                class="icon icon-eye-off"
                ref="oldPasswordShow"
                @click="clickTogglePassword('oldPassword')"
              ></i>
            </p>
          </div>
          <div>
            <div class="pass">新しいパスワード</div>
            <p>
              <input type="password" autocomplete="new-password" maxlength="256" ref="newPassword" v-model="newPassword" required /><i
                class="icon icon-eye-off"
                ref="newPasswordShow"
                @click="clickTogglePassword('newPassword')"
              ></i>
            </p>
          </div>
          <div>
            <div class="pass">新しいパスワード（確認）</div>
            <p>
              <input type="password" maxlength="256" ref="newPasswordConfirm" v-model="newPasswordConfirm" required /><i
                class="icon icon-eye-off"
                ref="newPasswordConfirmShow"
                @click="clickTogglePassword('newPasswordConfirm')"
              ></i>
            </p>
          </div>
        </div>
        <div class="password-request-right">
          <p>パスワード必須条件</p>
          <ul>
            <li><span :class="{ 'password-policy-ok': isPasswordPolicy('length') }">✔</span>12 文字以上</li>
            <li><span :class="{ 'password-policy-ok': isPasswordPolicy('number') }">✔</span>1 つの数字を含む</li>
            <li><span :class="{ 'password-policy-ok': isPasswordPolicy('upper') }">✔</span>1 つの大文字を含む</li>
            <li><span :class="{ 'password-policy-ok': isPasswordPolicy('lower') }">✔</span>1 つの小文字を含む</li>
            <li><span :class="{ 'password-policy-ok': isPasswordPolicy('special') }">✔</span>1 つの特殊文字を含む</li>
          </ul>
        </div>
      </div>
      <div class="error-message">{{ message }}</div>

      <div class="button-row" v-if="isButtonPasswordChange">
        <button class="button button-cancel" :disabled="cancelDisable" @click="$emit('closePasswordChangeModal')">キャンセル</button>
        <button class="button" :disabled="!isPasswordCheck" @click="clickPasswordChange()">変更する</button>
      </div>
      <div class="button-row" v-if="isButtonGotoTop">
        <button class="button" @click="clickGotoTop()">トップページへ</button>
      </div>
      <div class="button-row" v-if="isButtonClose">
        <button class="button" @click="$emit('closePasswordChangeModal')">閉じる</button>
      </div>
    </div>
  </div>
</template>

<script>
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';

export default {
  name: 'CognitoLoginPasswordChange',
  props: {
    subTitle: { type: String, default: '' },
    username: { type: String, default: '' },
    isPasswordExpired: { type: Boolean, default: false },
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      message: '',
      isButtonPasswordChange: true,
      isButtonGotoTop: false,
      isButtonClose: false,
      cancelDisable: this.isPasswordExpired,

      cognitoUser: null,
    };
  },
  created() {},
  computed: {
    isPasswordCheck() {
      if (this.oldPassword.length >= 12) {
        if (this.newPassword === this.newPasswordConfirm) {
          if (
            this.newPassword.length >= 12 &&
            /[\d]/.test(this.newPassword) &&
            /[A-Z]/.test(this.newPassword) &&
            /[a-z]/.test(this.newPassword) &&
            /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/.test(this.newPassword)
          ) {
            return true;
          }
        }
      }
      return false;
    },
  },
  methods: {
    clickGotoTop() {
      location.assign('/');
    },
    isPasswordPolicy(policy) {
      switch (policy) {
        case 'length':
          if (this.newPassword.length >= 12) return true;
          break;
        case 'number':
          if (/[\d]/.test(this.newPassword)) return true;
          break;
        case 'upper':
          if (/[A-Z]/.test(this.newPassword)) return true;
          break;
        case 'lower':
          if (/[a-z]/.test(this.newPassword)) return true;
          break;
        case 'special':
          if (/[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/.test(this.newPassword)) return true;
          break;
      }
      return false;
    },
    clickTogglePassword(ref) {
      const type = this.$refs[ref].getAttribute('type') === 'password' ? 'text' : 'password';
      this.$refs[ref].setAttribute('type', type);
      const inputPasswordShow = ref + 'Show';
      this.$refs[inputPasswordShow].classList.toggle('icon-eye');
    },

    clickPasswordChange() {
      if (!this.isValidPassword()) return;

      const USER_POOL_CONFIG = {
        UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
        ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
      };
      const userPool = new CognitoUserPool(USER_POOL_CONFIG);
      this.cognitoUser = this.createCognitoUser(userPool);
      const authenticationDetails = this.createAuthenticationDetails();

      this.authenticateUser(authenticationDetails);
    },

    isValidPassword() {
      if (this.newPassword !== this.newPasswordConfirm) {
        this.message = '「新しいパスワード（確認）」が異なります。';
        return false;
      }
      if (this.oldPassword === this.newPassword) {
        this.message = '同じパスワードは設定できません。';
        return false;
      }
      return true;
    },

    createCognitoUser(userPool) {
      return new CognitoUser({
        Username: this.username,
        Pool: userPool,
      });
    },

    createAuthenticationDetails() {
      return new AuthenticationDetails({
        Username: this.username,
        Password: this.oldPassword,
      });
    },

    authenticateUser(authenticationDetails) {
      this.cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: this.handleSuccess,
        onFailure: this.handleFailure,
        newPasswordRequired: this.handleNewPasswordRequired,
      });
    },

    handleSuccess(result) {
      console.debug('onSuccess', result);
      this.changeUserPassword();
    },

    handleFailure(err) {
      console.debug('onFailure', err);
      if (err.message === 'Incorrect username or password.') {
        this.message = '現在のパスワードが異なります。';
      } else if (err.message === 'Password does not conform to policy: Password not long enough') {
        this.message = 'パスワードの長さが足りません。';
      } else {
        this.message = '不明なエラーが発生しました。';
      }
    },

    handleNewPasswordRequired() {
      console.debug('FORCE_CHANGE_PASSWORD');
      this.cognitoUser.completeNewPasswordChallenge(
        this.newPassword,
        {},
        {
          onSuccess: (session) => {
            console.debug('Password change succeeded!', session);
            this.updatePasswordChangeDateAttribute();
            this.message = 'パスワードの変更が完了しました。';
            this.isButtonPasswordChange = false;
            this.isButtonGotoTop = true;
          },
          onFailure: (err) => {
            console.debug('Password change failed', err);
            this.message = this.defaultErrorMessage;
          },
        }
      );
    },

    changeUserPassword() {
      this.cognitoUser.changePassword(this.oldPassword, this.newPassword, (err, result) => {
        if (err) {
          console.error(err);
          this.message = '不明なエラーが発生しました。';
          return;
        }
        console.debug('Successfully changed password of the user.', result);
        this.updatePasswordChangeDateAttribute();
        this.message = 'パスワードの変更が完了しました。';
        this.isButtonPasswordChange = false;
        if (this.isPasswordExpired) {
          this.isButtonGotoTop = true;
        } else {
          this.isButtonClose = true;
        }
      });
    },

    updatePasswordChangeDateAttribute() {
      const attribute = new CognitoUserAttribute({
        Name: 'custom:PasswordChangeDate',
        Value: Math.floor(new Date().getTime() / 1000).toString(),
      });
      this.cognitoUser.updateAttributes([attribute], (err, result) => {
        if (err) {
          console.error('カスタム属性を更新できませんでした', err);
        } else {
          console.debug('カスタム属性が更新されました', result);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.change-password {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: white;
  padding: 32px;
  border-radius: 4px;
  .subTitle {
    color: var(--group02-4-color);
  }
  p {
    input {
      width: 288px;
      height: 2.6em;
      padding: 0 16px;
      text-align: center;
      font-size: var(--font-size-middle);
      background-color: white;
      border: 1px solid var(--gray-03-color);
      border-radius: 3px;
    }
    .icon {
      margin-left: -30px;
      font-size: 1.2em;
      cursor: pointer;
    }
  }
  .password-request {
    display: flex;
    align-items: flex-end;
    gap: 40px;
    .password-request-left {
      input.username {
        display: none;
      }
    }
    .password-request-right {
      display: flex;
      flex-direction: column;
      gap: 8px;
      ul {
        display: flex;
        flex-direction: column;
        span {
          margin-right: 8px;
          &.password-policy-ok {
            color: red;
          }
        }
      }
    }
  }
  .error-message {
    color: var(--secondary-color);
  }
  .button-row {
    display: flex;
    gap: 16px;
    .button {
      width: 144px;
      cursor: pointer;
      &.button-cancel {
        background-color: var(--gray-02-color);
        &:hover {
          background-color: var(--gray-01-color);
        }
      }
      &:disabled {
        background-color: var(--gray-03-color);
        cursor: not-allowed;
      }
    }
  }
}
</style>
