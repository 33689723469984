<template>
  <div class="search-性別年代-wrapper unselectable">
    <ul class="search-性別年代-条件">
      <li class="title">ファンの性別</li>
      <li class="item">
        <ul>
          <li><input type="radio" id="fanGenderNone" value="" v-model="selectedGender" checked /><label for="fanGenderNone">指定なし</label></li>
          <li><input type="radio" id="fanGenderMan" value="男性" v-model="selectedGender" /><label for="fanGenderMan">男性</label></li>
          <li><input type="radio" id="fanGenderWoman" value="女性" v-model="selectedGender" /><label for="fanGenderWoman">女性</label></li>
        </ul>
      </li>
      <li class="title">ファンの年代</li>
      <li class="item">
        <ul>
          <li><input type="radio" id="fanAgeNone" value="" v-model="selectedAge" checked /><label for="fanAgeNone">指定なし</label></li>
          <li><input type="radio" id="fanAge10" value="10代" v-model="selectedAge" /><label for="fanAge10">10代</label></li>
          <li><input type="radio" id="fanAge20" value="20代" v-model="selectedAge" /><label for="fanAge20">20代</label></li>
          <li><input type="radio" id="fanAge30" value="30代" v-model="selectedAge" /><label for="fanAge30">30代</label></li>
          <li><input type="radio" id="fanAge40" value="40代" v-model="selectedAge" /><label for="fanAge40">40代</label></li>
          <li><input type="radio" id="fanAge50" value="50代" v-model="selectedAge" /><label for="fanAge50">50代</label></li>
          <li><input type="radio" id="fanAge60" value="60代以上" v-model="selectedAge" /><label for="fanAge60">60代以上</label></li>
        </ul>
      </li>
      <li class="title" v-if="showCategory">カテゴリ</li>
      <li class="item" v-if="showCategory">
        <ul>
          <li v-for="label in categoryTab" :key="'selecteCategoryOrder' + label">
            <input type="checkbox" :id="label" :value="label" v-model="selectedCategory" :checked="selectedCategory.includes(label)" /><label :for="label">{{
              label
            }}</label>
          </li>
        </ul>
      </li>
    </ul>

    <div name="artist-list" class="card-area unselectable">
      <ArtistCardDemogra
        :artist-info="artistInfo"
        @pageMove="pageMove"
        @clickArtistFavorite="updateArtistFavorite(artistInfo.artist_code)"
        @setSearchArtistHistory="setSearchArtistHistory"
        v-for="artistInfo in searchArtist"
        :key="artistInfo.artist_code"
      />
      <template v-if="searchArtist.length === 0">
        <div class="card-area-not-find">
          <div class="searching-results-contents-count">該当する情報は見つかりませんでした。</div>
          <div class="searching-results-contents-request">
            アーティスト追加依頼は<a class="link-underline" href="mailto:info.grooveforceengagement@sme.co.jp?subject=GROOVEFORCE ENGAGEMENT お問い合わせ"
              >こちら</a
            >&nbsp;<i class="icon icon-email"></i>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ArtistCardDemogra from '@/components/Home/ArtistCardDemogra';

export default {
  name: 'SearchAgeGender',
  inject: ['provideArtistList'],
  components: {
    ArtistCardDemogra,
  },
  props: {
    setFavorite: { type: Function, default: () => ({}) },
    cardAreaMaxHeight: { type: String, default: '812' },
    showCategory: { type: Boolean, default: true },
    showType: { type: String, default: '' },
    initialSelectedCategory: { type: Array, default: () => ['アーティスト', 'アニメ・ゲーム'] },
  },
  data() {
    return {
      selectedGender: '',
      selectedAge: '',
      selecteCategoryOrder: ['アーティスト', 'アニメ・ゲーム', 'サイト', 'ソニーグループ'],
      selectedCategory: this.initialSelectedCategory,
    };
  },
  created() {},
  computed: {
    categoryTab() {
      let contentsTab = [];
      if (this.provideArtistList.length) {
        const contentsType = [...new Set(this.provideArtistList.map((e) => (e.type === 'アニメ' || e.type === 'ゲーム' ? 'アニメ・ゲーム' : e.type)))];
        contentsTab = this.selecteCategoryOrder.filter((order) => contentsType.includes(order));
      }
      return contentsTab;
    },
    searchArtist() {
      const isFanAge = (e) => e.fan_age !== '';
      const isFanGenderMatched = (e) => this.selectedGender === '' || e.fan_gender === this.selectedGender;
      const isFanAgeMatched = (e) => this.selectedAge === '' || e.fan_age === this.selectedAge;
      const isFanCategoryMatched = (e) =>
        (this.selectedCategory.includes('アニメ・ゲーム') && (e.type === 'アニメ' || e.type === 'ゲーム')) || this.selectedCategory.includes(e.type);
      const isShowType = (e) => (this.showType ? e.type === this.showType : true);
      return this.setFavorite(this.provideArtistList).filter(
        (e) => isFanAge(e) && isFanGenderMatched(e) && isFanAgeMatched(e) && isFanCategoryMatched(e) && isShowType(e)
      );
    },
  },
  methods: {
    pageMove(artistInfo) {
      this.$emit('pageMove', artistInfo);
    },
    updateArtistFavorite(artistCode) {
      this.$emit('clickArtistFavorite', artistCode);
    },
    setSearchArtistHistory() {
      this.$emit('setSearchArtistHistory');
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.search-性別年代-wrapper {
  .search-性別年代-条件 {
    margin-bottom: 24px;
    .title {
      display: block;
      font-weight: bold;
      font-size: 1.8rem;
      color: var(--primary-color);
      margin-bottom: 4px;
      &:not(:first-child) {
        margin-top: 12px;
      }
    }
    .item {
      display: block;
      ul {
        display: flex;
        gap: 8px;
        li {
          input[type='radio'],
          input[type='checkbox'] {
            display: none;
            &:checked + label {
              color: white;
              background-color: var(--primary-color);
            }
          }
          label {
            display: block;
            padding: 6px 16px;
            font-weight: bold;
            color: var(--primary-color);
            background-color: white;
            border: 1px solid var(--primary-color);
            border-radius: 4px;
            cursor: pointer;
            transition: 0.5s;
            &:hover {
              background-color: var(--group01-1-color);
            }
          }
        }
      }
    }
  }
  .card-area {
    width: var(--contents-width);
    display: grid;
    grid-template-columns: repeat(auto-fit, 294px);
    gap: 14px;
    max-height: calc(v-bind(cardAreaMaxHeight) * 1px);
    overflow-y: auto;
  }
  .card-area-not-find {
    width: max-content;
    text-align: center;
  }
}
</style>
