<template>
  <div class="login">
    <div class="logo-wrapper">
      <img src="/images/logo.webp" class="logo" />
    </div>

    <div class="login-column" v-if="!username">
      <div class="title">ログイン</div>
      <input
        type="email"
        name="username"
        autocomplete="username"
        placeholder="&#xe917; メールアドレス"
        ref="inputUsername"
        v-model="inputUsername"
        @keydown.enter="inputUsernameKeydown()"
        required
      />
      <button class="button" @click="clickInputUsername()" :disabled="!checkEmailAddress">次へ</button>
    </div>
    <div class="login-column" v-if="username">
      <button class="username" @click="clickUsername()">
        <span>〈</span>&nbsp;&nbsp;&nbsp;{{ username }}<input type="email" name="username" autocomplete="username" v-model="username" />
      </button>
      <p style="margin-left: -12px">
        <input
          type="password"
          name="password"
          autocomplete="current-password"
          maxlength="256"
          placeholder="&#xe918; パスワード"
          ref="inputPassword"
          v-model="password"
          @keydown.enter="inputPasswordKeydown()"
          required
        />
        <i class="icon icon-eye-off" ref="inputPasswordShow" @click="clickTogglePassword()"></i>
      </p>
      <div class="password-forget" @click="showPasswordForgotModal = true">パスワードをお忘れですか？</div>
      <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
      <button class="button" @click="clickCognitoLogin()" :disabled="!checkPassword">ログイン</button>
    </div>

    <CognitoLoginPasswordChange
      class="overlayView"
      sub-title="初回ログインのためパスワードの変更が必要です。"
      :username="username"
      @closePasswordChangeModal="showPasswordChangeModal = false"
      v-if="showPasswordChangeModal"
    />

    <CognitoLoginPasswordForgot
      class="overlayView"
      :username="username"
      @closePasswordForgotModal="showPasswordForgotModal = false"
      v-if="showPasswordForgotModal"
    />
  </div>
  <div class="logo-essensuous-wrapper">
    <span>supported by</span>
    <div>
      <img src="/images/logo-essensuous.webp" class="logo-essensuous" />
      <span class="logo-essensuous-trademark">&reg;</span>
    </div>
  </div>
</template>

<script>
import CognitoLoginPasswordChange from '@/components/CognitoLoginPasswordChange.vue';
import CognitoLoginPasswordForgot from '@/components/CognitoLoginPasswordForgot.vue';

export default {
  name: 'LoginPage',
  components: {
    CognitoLoginPasswordChange,
    CognitoLoginPasswordForgot,
  },
  data() {
    return {
      inputUsername: '',
      username: '',
      password: '',
      errorMessage: '',
      showPasswordChangeModal: false,
      showPasswordForgotModal: false,
    };
  },
  beforeCreate: function () {
    let totalCount = 5;
    let num = Math.ceil(Math.random() * totalCount);
    document.body.style.minWidth = 'unset';
    document.body.style.backgroundImage = 'linear-gradient(60deg, rgb(106, 110, 253, .74), rgb(208, 210, 247, .4)), URL(/images/back' + num + '.webp)';
    document.body.style.backgroundPosition = 'center center';
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.backgroundAttachment = 'fixed';
    document.body.style.backgroundSize = 'cover';
  },
  async created() {},
  computed: {
    checkEmailAddress() {
      if (this.inputUsername.match(/.+@.+\..+/)) {
        return true;
      }
      return false;
    },
    checkPassword() {
      if (this.password.length >= 8) {
        return true;
      }
      return false;
    },
  },
  methods: {
    inputUsernameKeydown() {
      if (this.checkEmailAddress) this.clickInputUsername();
    },
    inputPasswordKeydown() {
      if (this.checkPassword) this.clickCognitoLogin();
    },
    clickInputUsername() {
      const domains = process.env.VUE_APP_AZURE_AD_DOMAIN.split(',');
      const emailDomain = this.inputUsername.split('@')[1];
      if (domains.includes(emailDomain)) {
        // Azure AD ログイン
        this.$azureAdLogin();
      } else {
        // Cognito ログイン用パスワード入力
        this.username = this.inputUsername;
        this.$nextTick(function () {
          this.$refs.inputPassword.focus();
        });
      }
    },
    clickUsername() {
      this.username = this.password = this.errorMessage = '';
    },
    clickTogglePassword() {
      const type = this.$refs.inputPassword.getAttribute('type') === 'password' ? 'text' : 'password';
      this.$refs.inputPassword.setAttribute('type', type);
      this.$refs.inputPasswordShow.classList.toggle('icon-eye');
    },
    async clickCognitoLogin() {
      const reslut = await this.$cognitoLogin(this.username, this.password);
      switch (reslut) {
        case 'onSuccess':
          location.assign('/');
          break;
        case 'Incorrect':
          this.errorMessage = 'メールアドレスもしくはパスワードが違います';
          break;
        case 'newPasswordRequired':
          this.showPasswordChangeModal = true;
          break;
        default:
          this.errorMessage = reslut;
        // this.errorMessage = '不明なエラーが発生しました。';
      }
    },
  },
  mounted: function () {
    this.$refs.inputUsername.focus();
  },
  unmounted: function () {
    document.body.style.backgroundImage = '';
    document.body.style.backgroundPosition = '';
    document.body.style.backgroundRepeat = '';
    document.body.style.backgroundAttachment = '';
    document.body.style.backgroundSize = '';
  },
};
</script>

<style scoped lang="scss">
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  margin-top: 42vh;
  .logo-wrapper {
    .logo {
      width: 482px;
      filter: drop-shadow(0 0 0.5rem #714e75);
    }
  }
  .login-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.86);
    border-radius: 2px;
    padding: 32px 0;
    text-align: center;
    width: 440px;
    .title {
      color: var(--gray-02-color);
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 24px;
    }
    input {
      width: 352px;
      height: 3.2em;
      padding: 0 16px;
      text-align: center;
      font-size: var(--font-size-middle);
      background-color: white;
      border: 1px solid var(--gray-03-color);
      border-radius: 3px;
      &::placeholder {
        font-family: 'icomoon';
        color: #a0adca;
      }
    }
    .username {
      font-size: 16px;
      color: var(--gray-02-color);
      background-color: rgb(255 223 253 / 65%);
      max-width: 352px;
      overflow: hidden;
      margin-bottom: 24px;
      padding: 4px 16px;
      border: 1px solid var(--gray-03-color);
      border-radius: 16px;
      cursor: pointer;
      span {
        font-size: 12px;
      }
      input {
        display: none;
      }
    }
    .icon {
      margin-left: -30px;
      font-size: 1.2em;
      cursor: pointer;
    }
    .password-forget {
      margin-top: 12px;
      padding: 4px 8px;
      cursor: pointer;
      text-decoration: underline;
    }
    .error-message {
      color: var(--secondary-color);
    }
    .button {
      width: 352px;
      height: 40px;
      margin-top: 16px;
      border-radius: 4px;
      font-size: var(--font-size-middle);
      background-color: var(--gray-02-color);
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
      }
      &:not(:disabled) {
        box-shadow: 0 0 40px 40px rgb(246 103 214 / 60%) inset;
      }
      &:hover:not(:disabled),
      &:focus:not(:disabled) {
        outline: 0;
        box-shadow: 0 0 40px 40px rgb(246 103 214) inset;
      }
    }
  }
}
.logo-essensuous-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 12px;
  margin: auto 24px 24px auto;
  color: white;
  > span {
    margin-bottom: -4px;
  }
  div {
    position: relative;
    text-align: right;
    .logo-essensuous {
      width: 192px;
    }
    .logo-essensuous-trademark {
      position: absolute;
      top: -10px;
      font-size: 24px;
    }
  }
}
</style>
