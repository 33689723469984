<template>
  <div class="card">
    <a @click="pageMove(artistInfo)" class="card-image">
      <img :src="'/images/artists/' + encodeURI(artistInfo.artist_image_file_name)" :style="imagePreviewPosition" decoding="async" fetchpriority="low" />
    </a>

    <div class="artist-name-wrap">
      <a class="artist-name" ref="cardBodyLink" @click="pageMove(artistInfo)">{{ artistInfo.artist_name }}</a>
    </div>
    <div class="favorite-button" :class="{ favorite: artistInfo.isfavorite }" @click="$emit('clickArtistFavorite')">
      <template v-if="artistInfo.isfavorite">フォロー中</template><template v-else>フォローする<i class="icon icon-person_add_alt_1"></i></template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArtistCard',
  props: ['artistInfo'],
  computed: {
    imagePreviewPosition: function () {
      if (parseInt(this.artistInfo.image_position) || 0 !== 0) {
        const style = 'object-fit: unset;height: unset;position: relative;top: calc(-50% - ' + this.artistInfo.image_position + 'px)';
        return style;
      } else {
        return '';
      }
    },
  },
  mounted() {
    let fontSize = 18;
    const minFontSize = 12;
    this.$refs.cardBodyLink.style.fontSize = fontSize + 'px';
    while (this.$refs.cardBodyLink.getBoundingClientRect().width > 217) {
      fontSize = fontSize * 0.96;
      if (fontSize < minFontSize) {
        this.$refs.cardBodyLink.style['white-space'] = 'pre-line';
        this.$refs.cardBodyLink.style.fontSize = '12px';
        return;
      }
      this.$refs.cardBodyLink.style.fontSize = fontSize + 'px';
    }
  },
  methods: {
    pageMove(artistInfo) {
      this.$emit('pageMove', artistInfo);
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  --card-width: 233px;
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 3;
  row-gap: 12px;
  width: var(--card-width);
  background-color: white;
  border-radius: 6px;
  filter: drop-shadow(4px 4px 5px rgba(0, 0, 0, 0.35));
  .card-image {
    // display: block;
    height: 155px;
    overflow: hidden;
    border-radius: 6px 6px 0 0;
    img {
      width: var(--card-width);
      height: 155px;
      object-fit: cover;
      vertical-align: middle;
    }
  }
  .artist-name-wrap {
    display: grid;
    place-content: center;
    text-align: center;
    padding: 0 8px;
    .artist-name {
      font-size: 18px;
      font-weight: bold;
      white-space: nowrap;
      overflow-wrap: break-word;
      line-height: 1.4em;
      word-break: auto-phrase;
      color: var(--primary-color);
    }
  }
  .favorite-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 120px;
    margin-inline: auto;
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: bold;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    background-color: white;
    border-radius: 11px;
    cursor: pointer;
    z-index: 1;
    &.favorite {
      color: white;
      background-color: var(--primary-color);
    }
    .icon {
      margin-left: 6px;
    }
  }
}
</style>
