<template>
  <div class="FavoriteArtist" v-if="favoriteArtistList.length">
    <div class="title">フォロー中</div>
    <transition-group name="artist-list" tag="div" class="card-area">
      <template v-for="(artistInfo, index) in favoriteArtistList" :key="artistInfo.artist_code">
        <ArtistCard
          :artist-info="artistInfo"
          @pageMove="pageMove"
          @clickArtistFavorite="$emit('updateArtistFavorite', artistInfo.artist_code)"
          @setSearchArtistHistory="setSearchArtistHistory"
          v-if="viewMore || index <= 4"
        /> </template
    ></transition-group>
    <div class="view-more" v-if="!viewMore && favoriteArtistList.length > 5" @click="clickViewMore()">
      <i class="icon icon-keyboard_arrow_down"></i>もっと見る
    </div>
    <div class="view-more" v-if="viewMore" @click="clickViewMore()"><i class="icon icon-keyboard_arrow_up"></i>折りたたむ</div>
  </div>
</template>

<script>
import ArtistCard from '@/components/Home/ArtistCard';

export default {
  name: 'FavoriteArtist',
  components: { ArtistCard },
  props: ['favoriteArtistList'],
  data() {
    return {
      viewMore: false,
    };
  },
  created() {},
  methods: {
    pageMove(artistInfo) {
      this.$emit('pageMove', artistInfo);
    },
    setSearchArtistHistory(artist_code) {
      this.$emit('setSearchArtistHistory', artist_code);
    },
    clickViewMore() {
      if (this.favoriteArtistList.length > 5) {
        this.viewMore = !this.viewMore;
      } else {
        this.viewMore = false;
      }
    },
  },
  watch: {
    favoriteArtistList: {
      handler() {
        if (this.favoriteArtistList.length <= 5) {
          this.viewMore = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.FavoriteArtist {
  .title {
    font-weight: bold;
    font-size: 1.8rem;
    color: var(--primary-color);
  }
  .card-area {
    width: var(--contents-width);
    display: grid;
    grid-template-columns: repeat(auto-fit, 233px);
    // align-items: stretch;
    // align-content: stretch;
    gap: 32px 16px;
    padding: 12px 9px;
  }
  .view-more {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 200px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: var(--primary-color);
    border-radius: 16px;
    text-align: center;
    margin-top: 16px;
    margin-inline: auto;
    cursor: pointer;
    i {
      font-size: 28px;
    }
  }
}

.artist-list-leave-active {
  transition: opacity 0.3s;
}
.artist-list-leave-to {
  opacity: 0;
}
</style>
