export default {
  inject: {
    userAttribute: {
      default: () => ({}),
    },
  },
  methods: {
    $isEnvDevelopment() {
      return process.env.VUE_APP_ENV === 'development';
    },

    // 数値の 3 桁区切り
    $format3digitSeparator(value) {
      return typeof value === 'number' ? value.toLocaleString() : '0';
    },

    $adjustFontSizeToFit(targetElement, targetTextElement, fontSize, minFontSize) {
      // テキストが枠内に収まるまでフォントサイズを縮小する
      const adjustFontSize = (limitFontSize) => {
        while (targetTextElement.clientHeight > targetElement.clientHeight || targetTextElement.clientWidth > targetElement.clientWidth) {
          fontSize *= 0.96;
          if (fontSize < limitFontSize) break;
          targetTextElement.style.fontSize = fontSize + 'px';
        }
      };

      const orgFontSize = fontSize;
      targetTextElement.style.fontSize = fontSize + 'px';
      targetTextElement.style.whiteSpace = 'nowrap';

      // 一定サイズまでは、改行無しで収まるようにする
      adjustFontSize(orgFontSize * 0.9);

      // それでも収まらない場合は、改行ありで収まるようにする
      if (targetTextElement.clientWidth > targetElement.clientWidth) {
        targetTextElement.style.whiteSpace = 'pre-line';
        targetTextElement.style.overflowWrap = 'anywhere';
        // targetTextElement.style.wordBreak = 'auto-phrase';
        targetTextElement.style.position = 'unset';
        adjustFontSize(minFontSize);
      }
    },

    $adjustArtistNameForDisplay(artistName) {
      switch (artistName) {
        case '澤野弘之/SawanoHiroyuki[nZk]':
          return '澤野弘之/<wbr />SawanoHiroyuki[nZk]';
        case 'RPGタイム！～ライトの伝説～':
          return 'RPGタイム！<span>～ライトの伝説～</span>';
        case '龍族 -The Blazing Dawn-':
          return '龍族<span>-The Blazing Dawn-';
        case 'るろうに剣心 －明治剣客浪漫譚－':
          return 'るろうに剣心<span>－明治剣客浪漫譚－</span>';
        case 'イコライザー THE FINAL':
          return 'イコライザー<span>THE FINAL</span>';
        case '傷物語 -こよみヴァンプ-':
          return '傷物語<span>-こよみヴァンプ-</span>';
        case 'ソニーグループポータル トップページ':
          return 'ソニーグループ<wbr />ポータル トップページ';
        default:
          return artistName;
      }
    },

    $getBarChartMaxValue(dataset, column) {
      let valueMax = Math.max(...dataset.map((p) => p[column]));
      if (valueMax > 75) {
        valueMax = 100;
      } else {
        valueMax *= 1.2;
      }
      return valueMax;
    },

    $getToday() {
      const zeroPad = (num) => {
        return num.toString().padStart(2, '0');
      };
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = zeroPad(currentDate.getMonth() + 1); // 0-indexed なので +1 する
      const date = zeroPad(currentDate.getDate());
      return `${year}-${month}-${date}`;
    },
    $getDateTime() {
      const zeroPad = (num) => {
        return num.toString().padStart(2, '0');
      };
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = zeroPad(currentDate.getMonth() + 1); // 0-indexed なので +1 する
      const date = zeroPad(currentDate.getDate());
      const hour = zeroPad(currentDate.getHours());
      const minutes = zeroPad(currentDate.getMinutes());
      const second = zeroPad(currentDate.getSeconds());
      return `${year}-${month}-${date} ${hour}:${minutes}:${second}`;
    },
    $yyyy年mm月(dateString) {
      // yyyy-mm-dd -> yyyy年mm月
      if (dateString === undefined) return '';
      return dateString.length === 7 || dateString.length === 10 ? dateString.slice(0, 4) + '年' + dateString.slice(5, 7) + '月' : dateString;
    },
    $yyyy年mm月dd日(dateString) {
      // yyyy-mm-dd -> yyyy年mm月dd日
      if (dateString === undefined) return '';
      return dateString.length === 10 ? dateString.slice(0, 4) + '年' + dateString.slice(5, 7) + '月' + dateString.slice(8, 10) + '日' : dateString;
    },
    $yyyy_mm_dd(dateString) {
      // yyyy年mm月dd日 -> yyyy-mm-dd
      if (dateString === undefined) return '';
      return dateString.length === 11 ? dateString.slice(0, 4) + '-' + dateString.slice(5, 7) + '-' + dateString.slice(8, 10) : dateString;
    },
    $yyyy_mm_dd2(dateString) {
      // yyyymmdd -> yyyy/mm/dd
      if (dateString === undefined) return '';
      return dateString.length === 8 ? dateString.slice(0, 4) + '-' + dateString.slice(4, 6) + '-' + dateString.slice(6, 8) : dateString;
    },
    $formatDateToYYYYMMDD(date) {
      // date -> yyyy-mm-dd
      try {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
      } catch (e) {
        return '';
      }
    },

    $sortedPrefectures(dataset) {
      const prefecturesOrder = this.$prefectureMap();
      return dataset.sort((a, b) => {
        return prefecturesOrder.indexOf(a) - prefecturesOrder.indexOf(b);
      });
    },
    $prefectureMap() {
      // prettier-ignore
      return [
        '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県',
        '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県',
        '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県',
        '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県',
        '鳥取県', '島根県', '岡山県', '広島県', '山口県',
        '徳島県', '香川県', '愛媛県', '高知県',
        '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県',
      ];
    },
    $getPrefectureNo(prefecture) {
      const prefectureArray = this.$prefectureMap();
      return prefectureArray.indexOf(prefecture);
    },
    $prefectureByRegion() {
      // prettier-ignore
      return [
        { 北海道: ['北海道'] },
        { 東北: ['青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県'] },
        { 関東: ['茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県'] },
        { 甲信越: ['新潟県', '山梨県', '長野県'] },
        { 北陸: ['富山県', '石川県', '福井県'] },
        { 東海: ['岐阜県', '静岡県', '愛知県'] },
        { 近畿: ['三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県'] },
        { 中国: ['鳥取県', '島根県', '岡山県', '広島県', '山口県'] },
        { 四国: ['徳島県', '香川県', '愛媛県', '高知県'] },
        { '九州・沖縄': ['福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'] },
      ];
    },
    $get10地方区分(prefecture) {
      const 地方区分 = this.$prefectureByRegion();
      const result = 地方区分.find((region) => {
        const prefectures = Object.values(region)[0];
        return prefectures.includes(prefecture);
      });

      return result ? Object.keys(result)[0] : '';
    },

    zen2han: function (str) {
      return str.replace(/[\u3000]/g, ' ').replace(/[！-～]/g, function (tmpStr) {
        return String.fromCharCode(tmpStr.charCodeAt(0) - 0xfee0).replace(/−/g, '-');
      });
    },
    han2zen: function (str) {
      // prettier-ignore
      var kanaMap = {
        'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
        'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
        'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
        'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
        'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
        'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
        'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
        'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
        'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
        'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
        'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
        'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
        'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
        'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
        'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
        'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
        'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
        'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
        '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・', 'ﾞ': '゛', 'ﾟ': '゜'
    };
      var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
      return str.replace(reg, function (match) {
        return kanaMap[match];
      });
    },
    $zen2han: function (str) {
      return this.zen2han(this.han2zen(str));
    },

    $isViewMenu(categoryMenuArray) {
      if (categoryMenuArray.denyEmail) {
        if (categoryMenuArray.denyEmail.find((e) => e === this.userAttribute.email)) return false;
      }
      return true;
    },

    $updatePageTitle(artistName) {
      let title = document.title;
      const artistPattern = /\(.*\) - /;
      if (artistName) {
        if (artistPattern.test(title)) {
          title = title.replace(artistPattern, `(${artistName}) - `);
        } else {
          title = title.replace(/ - /, `(${artistName}) - `);
        }
      } else {
        title = title.replace(artistPattern, '');
      }
      document.title = title;
    },
  },
};
