import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { msalPlugin } from './plugins/msalPlugin';
import { msalInstance } from './azureAdAuthConfig';
import FloatingVue from 'floating-vue';
import utility from './mixins/utility';
import utilityFetch from './mixins/utilityFetch';
import utilityAuth from './mixins/utilityAuth';

import 'floating-vue/dist/style.css';
import './styles/reset.css';
import './styles/common.scss';
import './styles/webfont.css';

const app = createApp(App);
app.config.unwrapRefs = true;

app.use(router);
app.use(msalPlugin, msalInstance);

app.use(FloatingVue, {
  themes: {
    tooltip: {
      triggers: ['hover'],
      autoHide: true,
      placement: 'bottom',
      followMouse: true,
    },
  },
});

app.mixin(utility);
app.mixin(utilityFetch);
app.mixin(utilityAuth);

router.isReady().then(() => {
  app.mount('#app');
});
