<template>
  <div class="side-menu" ref="sideMenu">
    <ul class="menu unselectable" ref="menu">
      <li class="category" v-for="topMenu in menuList" :key="topMenu.title">
        <div class="category-title">
          <i class="icon" :class="'icon-' + topMenu.icon" v-if="topMenu.icon !== 'anime'"></i
          ><img class="icon icon-anime" src="/images/icons/anime.png" v-else />{{ topMenu.title }}
        </div>
        <ul class="sub-menu" v-if="topMenu.category">
          <template v-for="subMenu in topMenu.category" :key="topMenu.title + subMenu.title">
            <li class="item" v-if="$isViewMenu(subMenu)">
              <span class="active-item" v-if="$route.path == subMenu.url">{{ subMenu.title }}</span>
              <span class="disable-link" v-else-if="disableLink(subMenu.url)">{{ subMenu.title }}</span>
              <a v-else-if="subMenu.url" :href="createLinkUrl(subMenu.url)">{{ subMenu.title }}</a>
            </li>
          </template>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SideMenu',
  inject: ['artistAndTitle', 'provideMenuList'],
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  computed: {
    menuList() {
      this.handleResize();
      return this.provideMenuList;
    },
  },
  methods: {
    disableLink(url) {
      if (
        url === '/artist-analytics' ||
        url === '/artist-youtubecomments' ||
        url === '/artist-tieup' ||
        url === '/purchase-analytics' ||
        // url === '/artist-benchmark' ||
        url === '/sonygroup-analytics' ||
        url === '/site-analytics' ||
        url === '/fanclub-analytics' ||
        url === '/goods-analytics' ||
        // url === '/events-analytics' ||
        url === '/live-hall-visitor-analytics' ||
        url === '/trends-generationz' ||
        url === '/trends-anime' ||
        url === '/crm-analytics' ||
        url === '/anime-analytics' ||
        url === '/game-analytics' ||
        // url === '/aniplex-analytics' ||
        // url === '/anime-nextbreak-analytics' ||
        url === '/report-list' ||
        url === '/manage-user' ||
        url === '/manage-artist' ||
        url === '/manage-report' ||
        url === '/manage-news' ||
        url === '/manage-access-log'
      ) {
        return false;
      }
      return true;
    },
    createLinkUrl(url) {
      let query = '';
      if (
        url !== undefined &&
        !(
          url.indexOf('/live-hall-visitor-analytics') === 0 ||
          url.indexOf('/trends-') === 0 ||
          url.indexOf('/report-list') === 0 ||
          url.indexOf('/manage-') === 0
        )
      ) {
        query = '?artist_code=' + this.artistAndTitle.source.artistObj?.artist_code;
        if (this.artistAndTitle.target.artistObj?.artist_code) {
          query += '&artist2_code=' + this.artistAndTitle.target.artistObj?.artist_code;
        }
      }
      return url + query;
    },
    handleResize() {
      this.$nextTick(() => {
        const de = getComputedStyle(document.documentElement);
        const headerHeight = parseInt(de.getPropertyValue('--header-bar-height'));
        this.$refs.sideMenu.style.maxHeight = document.documentElement.clientHeight - headerHeight + 'px';
      });
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped lang="scss">
.side-menu {
  position: sticky;
  top: var(--header-bar-height);
  padding: 30px 0 20px;
  overflow-x: hidden;
  overflow-y: auto;
  .menu {
    display: block flex;
    flex-direction: column;
    gap: 16px;
    padding-left: 8px;
    width: var(--side-menu-width);
    .category {
      font-size: var(--font-size-middle2);
      .category-title {
        padding: 16px 0;
        color: var(--primary-color);
        font-weight: bold;
        letter-spacing: 0.16em;
        .icon {
          font-size: 0.9em;
          margin-right: 8px;
          &.icon-anime {
            height: 17px;
            display: inline;
          }
        }
      }
      .sub-menu {
        display: flex;
        flex-direction: column;
        gap: 14px;
        margin-top: 4px;
        margin-left: 1.3em;
        font-size: var(--font-size-middle);
        letter-spacing: 0.8px;
        .item {
          display: flex;
          flex-direction: column;
          .active-item {
            color: var(--secondary-color);
            font-weight: bold;
          }
          .disable-link {
            color: var(--gray-03-color);
            cursor: not-allowed;
          }
          a {
            display: block;
            &:hover {
              padding-left: 4px;
              border-left: 6px solid var(--secondary-color);
              transition: all 0.3s ease;
            }
          }
        }
      }
    }
  }
}
</style>
