<template>
  <div>
    <div class="change-password">
      <h1>パスワードの再設定</h1>
      <p>{{ username }} へ確認コードをお送りします。</p>
      <button class="button button-send-code" @click="clickSendCode()" :disabled="!isSendCode">コードの送信</button>
      <div class="password-request">
        <div class="password-request-left">
          <div>
            <input type="email" name="username" autocomplete="username" class="username" :value="username" />
            <div class="pass">お送りした確認コード</div>
            <p>
              <input type="text" maxlength="256" v-model="verificationCode" required />
            </p>
          </div>
          <div>
            <div class="pass">新しいパスワード</div>
            <p>
              <input type="password" autocomplete="new-password" maxlength="256" ref="newPassword" v-model="newPassword" required /><i
                class="icon icon-eye-off"
                ref="newPasswordShow"
                @click="clickTogglePassword('newPassword')"
              ></i>
            </p>
          </div>
          <div>
            <div class="pass">新しいパスワード（確認）</div>
            <p>
              <input type="password" maxlength="256" ref="newPasswordConfirm" v-model="newPasswordConfirm" required /><i
                class="icon icon-eye-off"
                ref="newPasswordConfirmShow"
                @click="clickTogglePassword('newPasswordConfirm')"
              ></i>
            </p>
          </div>
        </div>
        <div class="password-request-right">
          <p>パスワード必須条件</p>
          <ul>
            <li><span :class="{ 'password-policy-ok': isPasswordPolicy('length') }">✔</span>12 文字以上</li>
            <li><span :class="{ 'password-policy-ok': isPasswordPolicy('number') }">✔</span>1 つの数字を含む</li>
            <li><span :class="{ 'password-policy-ok': isPasswordPolicy('upper') }">✔</span>1 つの大文字を含む</li>
            <li><span :class="{ 'password-policy-ok': isPasswordPolicy('lower') }">✔</span>1 つの小文字を含む</li>
            <li><span :class="{ 'password-policy-ok': isPasswordPolicy('special') }">✔</span>1 つの特殊文字を含む</li>
          </ul>
        </div>
      </div>
      <div class="error-message">{{ errorMessage }}</div>

      <div class="button-row" v-if="isButtonPasswordChange">
        <button class="button button-cancel" @click="$emit('closePasswordForgotModal')">キャンセル</button>
        <button class="button" :disabled="!isPasswordCheck" @click="clickPasswordChange()">変更する</button>
      </div>
      <div class="button-row" v-if="isButtonClose">
        <button class="button" @click="$emit('closePasswordForgotModal')">閉じる</button>
      </div>
    </div>
  </div>
</template>

<script>
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

export default {
  name: 'CognitoLoginPasswordForgot',
  props: {
    username: { type: String, default: '' },
  },
  data() {
    return {
      isSendCode: true,
      verificationCode: '',
      newPassword: '',
      newPasswordConfirm: '',
      errorMessage: '',
      isButtonPasswordChange: true,
      isButtonClose: false,
    };
  },
  created() {},
  computed: {
    isPasswordCheck() {
      if (this.verificationCode.length) {
        if (this.newPassword === this.newPasswordConfirm) {
          if (
            this.newPassword.length >= 12 &&
            /[0-9]/.test(this.newPassword) &&
            /[A-Z]/.test(this.newPassword) &&
            /[a-z]/.test(this.newPassword) &&
            /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/.test(this.newPassword)
          ) {
            return true;
          }
        }
      }
      return false;
    },
  },
  methods: {
    clickSendCode() {
      const poolData = {
        UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
        ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
      };
      const userPool = new CognitoUserPool(poolData);
      const userData = {
        Username: this.username,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);

      const self = this;
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          console.debug('call result: ' + result);
        },
        onFailure: function (err) {
          alert(err);
        },
        inputVerificationCode() {
          self.isSendCode = false;
        },
      });
    },
    isPasswordPolicy(policy) {
      switch (policy) {
        case 'length':
          if (this.newPassword.length >= 12) return true;
          break;
        case 'number':
          if (/[0-9]/.test(this.newPassword)) return true;
          break;
        case 'upper':
          if (/[A-Z]/.test(this.newPassword)) return true;
          break;
        case 'lower':
          if (/[a-z]/.test(this.newPassword)) return true;
          break;
        case 'special':
          if (/[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/.test(this.newPassword)) return true;
          break;
      }
      return false;
    },
    clickTogglePassword(ref) {
      const type = this.$refs[ref].getAttribute('type') === 'password' ? 'text' : 'password';
      this.$refs[ref].setAttribute('type', type);
      const inputPasswordShow = ref + 'Show';
      this.$refs[inputPasswordShow].classList.toggle('icon-eye');
    },
    clickPasswordChange() {
      if (this.newPassword !== this.newPasswordConfirm) {
        this.errorMessage = '「新しいパスワード（確認）」が異なります。';
        return;
      }
      const poolData = {
        UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
        ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
      };
      const userPool = new CognitoUserPool(poolData);
      const userData = {
        Username: this.username,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);

      const self = this;
      cognitoUser.confirmPassword(this.verificationCode, this.newPassword, {
        onSuccess: function (result) {
          console.debug('onSuccess', result);
          self.errorMessage = 'パスワードの設定が完了しました。';
          self.isButtonPasswordChange = false;
          self.isButtonClose = true;
        },
        onFailure: function (err) {
          console.error('onFailure');
          console.error(err);
          console.error(err.message);
          if (err.message === 'Incorrect username or password.') self.errorMessage = '現在のパスワードが異なります。';
          else if (err.message === 'Password does not conform to policy: Password not long enough') self.errorMessage = 'パスワードの長さが足りません。';
          else self.errorMessage = '不明なエラーが発生しました。';
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.change-password {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: white;
  padding: 32px;
  border-radius: 4px;
  .button-send-code {
    cursor: pointer;
    &:disabled {
      background-color: var(--gray-03-color);
      cursor: not-allowed;
    }
  }
  p {
    input {
      width: 288px;
      height: 2.6em;
      padding: 0 16px;
      text-align: center;
      font-size: var(--font-size-middle);
      background-color: white;
      border: 1px solid var(--gray-03-color);
      border-radius: 3px;
    }
    .icon {
      margin-left: -30px;
      font-size: 1.2em;
      cursor: pointer;
    }
  }
  .password-request {
    display: flex;
    align-items: flex-end;
    gap: 40px;
    .password-request-left {
      input.username {
        display: none;
      }
    }
    .password-request-right {
      display: flex;
      flex-direction: column;
      gap: 8px;
      ul {
        display: flex;
        flex-direction: column;
        span {
          margin-right: 8px;
          &.password-policy-ok {
            color: red;
          }
        }
      }
    }
  }
  .error-message {
    color: var(--secondary-color);
  }
  .button-row {
    display: flex;
    gap: 16px;
    .button {
      width: 144px;
      cursor: pointer;
      &.button-cancel {
        background-color: var(--gray-02-color);
        &:hover {
          background-color: var(--gray-01-color);
        }
      }
      &:disabled {
        background-color: var(--gray-03-color);
        cursor: not-allowed;
      }
    }
  }
}
</style>
