<template>
  <header class="header-bar">
    <div class="header">
      <div class="logo">
        <a href="/">
          <img class="logo-image" src="/images/logo.webp" />
        </a>
      </div>
      <!-- <div>
        <p style="font-size: 2em; font-weight: bold; color: #ff8bad">現在メンテナンス中です。</p>
        <p style="color: white">不都合が発生する恐れがあります。(終了予定: 5/7 9:00)</p>
      </div> -->
      <ul class="item-line">
        <!-- <li class="item item-manual" @click="openManualOverlay()">
          <i class="icon icon-help_outline"></i><span>使い方マニュアル</span><i class="icon icon-arrow_right"></i>
        </li> -->
        <li class="item item-info" onclick="location.href = 'mailto:info.grooveforceengagement@sme.co.jp?subject=GROOVEFORCE ENGAGEMENT お問合せ'">
          <i class="icon icon-email"></i><span>お問合せ</span><i class="icon icon-arrow_right"></i>
        </li>
        <!-- <li class="item">
          <a href="#"><span class="icon icon-bell"></span></a>
        </li> -->
        <li class="item item-user">
          <i class="icon icon-user"></i>
          <ul class="sub-items">
            <li class="user-name-wrapper">
              アカウント<br />
              <span class="user-name">{{ userAttribute.email }}</span>
            </li>
            <li v-if="userAttribute.authProvider === 'cognito'">
              <a href="#" @click="showPasswordChangeModal = true" style="margin-bottom: 12px">パスワードの変更</a>
            </li>
            <li><a href="#" @click="openLogoutModal()">ログアウト</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </header>

  <ManualOverlay
    class="manual-view"
    :class="{ visible: isManualOverlay === true, hidden: isManualOverlay === false }"
    :manual-json="manualJson"
    @closeManualOverlay="closeManualOverlay"
  />

  <CognitoLoginPasswordChange
    class="overlayView"
    :username="userAttribute.email"
    @closePasswordChangeModal="showPasswordChangeModal = false"
    v-if="showPasswordChangeModal"
  />
  <div class="logout-overlay" :style="{ visibility: showLogoutModal ? 'visible' : 'hidden', opacity: showLogoutModal ? '1' : '0' }" @click="closeLogoutModal()">
    <div class="content unselectable">
      <p><span class="icon-log-out"></span>ログアウトしますか?</p>
      <div class="button-row">
        <div class="button" @click="closeLogoutModal()">いいえ</div>
        <div class="button" @click="$logout()">ログアウト</div>
      </div>
    </div>
  </div>
</template>

<script>
import CognitoLoginPasswordChange from '@/components/CognitoLoginPasswordChange.vue';
import ManualOverlay from '@/components/ManualOverlay.vue';

export default {
  name: 'HeaderBar',
  inject: ['userAttribute'],
  components: {
    CognitoLoginPasswordChange,
    ManualOverlay,
  },
  data() {
    return {
      showLogoutModal: false,
      showPasswordChangeModal: false,
      isManualOverlay: null,
      manualJson: {},
    };
  },
  async created() {
    // this.manualJson = await this.getManualJson();
  },
  mounted() {},
  methods: {
    openManualOverlay: function () {
      this.isManualOverlay = true;
    },
    closeManualOverlay: function () {
      this.isManualOverlay = false;
    },
    openLogoutModal: function () {
      this.showLogoutModal = true;
    },
    closeLogoutModal: function () {
      this.showLogoutModal = false;
    },
    getManualJson() {
      return fetch('/master/manual.json').then((response) => response.json());
    },
  },
};
</script>

<style scoped lang="scss">
.header-bar {
  background-color: var(--primary-color);
  position: sticky;
  top: 0;
  z-index: 1300;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--header-bar-height);
    width: var(--container-width);
    margin-inline: auto;
    padding: 0 16px;
    .logo img {
      width: 198px;
      height: 39px;
    }
    .item-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 32px;
      .item {
        .icon {
          color: white;
        }
        &.item-manual,
        &.item-info {
          display: flex;
          align-items: center;
          height: 32px;
          border: 1px solid white;
          border-radius: 3px;
          cursor: pointer;
          .icon-help_outline,
          .icon-email {
            font-size: 22px;
            margin: 0;
            margin-left: 8px;
            padding: 0;
          }
          span {
            margin: 0 8px 0 4px;
            color: white;
          }
          .icon-arrow_right {
            display: flex;
            align-items: center;
            height: 100%;
            font-size: 24px;
            color: var(--primary-color);
            background-color: white;
          }
          transition: 0.2s;
          &:hover {
            background-color: #0067a7;
          }
        }
        &.item-user {
          margin-top: 4px;
          padding: 5px 3px 2px 5px;
          cursor: pointer;
          transition: 0.2s;
          .icon-user {
            font-size: 28px;
          }
          .sub-items {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            margin-left: -250px;
            padding: 4px 4px 16px;
            text-align: left;
            background: white;
            box-shadow: 0px 3px 5px -1px #ccc;
            height: 0;
            transition: 0.2s;
            li {
              clear: both;
              width: 100%;
              text-align: left;
              margin-bottom: 12px;
              border-style: none;
              padding-left: 0;
              &.user-name-wrapper {
                display: block;
                width: 280px;
                overflow: hidden;
                padding: 10px 16px 8px;
                background-color: var(--gray-04-color);
                .user-name {
                  font-size: 12px;
                  letter-spacing: 0.02em;
                }
              }
              a {
                display: block;
                padding: 6px 16px;
                &:hover {
                  padding-left: 10px;
                  border-left: 10px solid var(--secondary-color);
                  transition: all 0.3s ease;
                }
              }
            }
            &.active {
              visibility: visible;
              opacity: 1;
              z-index: 1310;
              display: block;
              margin-top: -10px;
              // margin-left: -142px;
              min-width: 12.8em;
              height: auto;
              transition: 0.2s;
            }
          }
          &:hover {
            background-color: #0067a7;
            border-radius: 50%;
          }
        }
        &:hover > .sub-items {
          visibility: visible;
          opacity: 1;
          z-index: 1310;
          display: block;
          margin-top: -10px;
          // margin-left: -142px;
          min-width: 12.8em;
          height: auto;
          transition: 0.2s;
        }
      }
    }
  }
}

.overlayView {
  z-index: 1400 !important;
}

.manual-view {
  visibility: hidden;
  opacity: 0;
  &.hidden {
    animation: SlideOut 0.6s;
    @keyframes SlideOut {
      0% {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
      }
      100% {
        visibility: hidden;
        opacity: 0;
        transform: translateX(400px);
      }
    }
  }
  &.visible {
    visibility: visible;
    opacity: 1;
    animation: SlideIn 0.6s;
    @keyframes SlideIn {
      0% {
        visibility: hidden;
        opacity: 0;
        transform: translateX(400px);
      }
      100% {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}

.logout-overlay {
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.25s linear;
  .content {
    z-index: 2001;
    display: flex;
    flex-direction: column;
    width: 34rem;
    height: 14em;
    background: white;
    border-radius: 5px;
    position: relative;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      span {
        margin-right: 1rem;
        font-size: 1.8rem;
      }
    }
    .button-row {
      display: flex;
      cursor: pointer;
      .button {
        width: 50%;
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &:first-child {
          color: var(--text-color);
          background-color: var(--gray-04-color);
          border-radius: 0 0 0 5px;
          &:hover {
            background-color: var(--gray-03-color);
          }
        }
        &:last-child {
          color: white;
          background-color: var(--primary-color);
          border-radius: 0 0 5px 0;
          &:hover {
            background-color: var(--group01-6-color);
          }
        }
      }
    }
  }
}
</style>
