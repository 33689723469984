<template>
  <ul class="manual-wrapper">
    <li class="accordion-item" v-for="(manualCategory, indexCategory) in manualFilter" :key="indexCategory">
      <button class="accordion-title" ref="categoryTitle" @click="detailOpenToggle(indexCategory)">
        <h4>{{ manualCategory.category_title }}</h4>
        <i class="icon icon-arrow_drop_down" :class="{ active: detailVisible(indexCategory) }"></i>
      </button>

      <div class="accordion__content" v-show="detailVisible(indexCategory)">
        <p class="link-info">↓ こちらのページの情報を見てみましょう。</p>

        <template v-if="manualCategory.items.length === 1">
          <p class="link" v-html="createLink(manualCategory.items[0].destination)"></p>
          <div v-if="manualCategory.items[0].how_to" class="how_to">{{ manualCategory.items[0].how_to }}</div>
          <div v-if="manualCategory.items[0].supplement" class="supplement">{{ manualCategory.items[0].supplement }}</div>
          <img
            class="image"
            loading="lazy"
            decoding="async"
            fetchpriority="low"
            :width="manualCategory.items[0].image_width"
            :src="'/images/manual/how-to/' + manualCategory.items[0].image_file"
            v-if="manualCategory.items[0].image_file"
          />
          <div class="info">お問い合わせは<a href="mailto:info.grooveforceengagement@sme.co.jp?subject=GROOVEFORCE ENGAGEMENT お問い合わせ">こちら</a></div>
        </template>

        <template v-else>
          <ul class="manual-wrapper">
            <li class="accordion-item" v-for="(itemLine, indexDetail) in manualCategory.items" :key="indexCategory + '-' + indexDetail">
              <button class="accordion-title" @click="detailInDetailOpenToggle(indexDetail)">
                <h4>{{ itemLine.destination }}</h4>
                <i class="icon icon-arrow_drop_down" :class="{ active: detailInDetailVisible(indexDetail) }"></i>
              </button>
              <div class="accordion__content" v-show="detailInDetailVisible(indexDetail)">
                <p class="link" v-html="createLink(itemLine.destination)"></p>
                <div v-if="itemLine.how_to" class="how_to">{{ itemLine.how_to }}</div>
                <div v-if="itemLine.supplement" class="supplement">{{ itemLine.supplement }}</div>
                <img
                  class="image"
                  :width="itemLine.image_width"
                  :src="'/images/manual/how-to/' + itemLine.image_file"
                  loading="lazy"
                  decoding="async"
                  fetchpriority="low"
                  v-if="itemLine.image_file"
                />
                <div class="info">
                  お問い合わせは<a href="mailto:info.grooveforceengagement@sme.co.jp?subject=GROOVEFORCE ENGAGEMENT お問い合わせ">こちら</a>
                </div>
              </div>
            </li>
          </ul>
        </template>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ManualOverlayAccordionItem',
  props: {
    manualFilter: { default: () => [] },
  },
  data() {
    return {
      activeCategory: null,
      activeDetail: null,
    };
  },
  created() {},
  computed: {},
  methods: {
    detailVisible(index) {
      return this.activeCategory === index;
    },
    detailInDetailVisible(index) {
      return this.activeDetail === index;
    },
    detailOpenToggle(index) {
      this.activeCategory = this.activeCategory === index ? null : index;
      this.activeDetail = null;
      const moveTop = (this.$refs.categoryTitle[index].getBoundingClientRect().height + 20) * index;
      if (this.activeCategory !== null)
        this.$nextTick(function () {
          document.getElementById('manual-area').scroll(0, moveTop);
        });
    },
    detailInDetailOpenToggle(index) {
      this.activeDetail = this.activeDetail === index ? null : index;
    },
    createLink(pageString) {
      const searchParams = new URLSearchParams(window.location.search);
      let queryArray = [];
      for (const [key, value] of searchParams) {
        queryArray.push(`${key}=${value}`);
      }
      let addQueryString = '?' + queryArray.join('&');

      let reslut = [];
      if (pageString.indexOf('サマリー') === 0) {
        const dir = '/artist-summary';
        reslut = pageString.split('>').map((e) => {
          const anchor = e.trim();
          switch (anchor) {
            case 'サマリー':
              return `<a href="${dir}${addQueryString}">${anchor}</a>`;
            case 'ペルソナ（インサイト）':
            case '特徴的なペルソナ':
            case '類似アーティスト':
              return `<a href="${dir}${addQueryString}#${anchor}">${anchor}</a>`;
            default:
              return anchor;
          }
        });
      } else if (pageString.indexOf('デモグラ') === 0) {
        const dir = '/artist-demo-graphic';
        reslut = pageString.split('>').map((e) => {
          const anchor = e.trim();
          switch (anchor) {
            case 'デモグラ':
              return `<a href="${dir}${addQueryString}">${anchor}</a>`;
            case '男女比':
            case '年代・性別':
            case '未婚・既婚':
            case '子供の有無':
            case '世帯年収':
            case 'ライブチケットを購入したことのある他のアーティスト':
            case 'ローチケ チケット購買データ':
            case '親和率':
            case '購買数':
            case 'HMV 購買データ':
            case 'アーティスト別':
            case '商品別':
            case '都道府県ヒートマップ':
            case 'メディア利用頻度':
            case 'テレビ視聴時間':
            case 'サイト・アプリ利用状況':
            case 'ソーシャルメディア':
            case '動画系・音楽系':
            case 'ニュース・エンタメ・生活情報':
            case 'ショッピング系':
            case '検索・ポータル系':
            case 'Web接触カテゴリ分析':
              return `<a href="${dir}${addQueryString}#${anchor}">${anchor}</a>`;
            default:
              return anchor;
          }
        });
      } else if (pageString.indexOf('インサイト') === 0) {
        const dir = '/artist-insight';
        reslut = pageString.split('>').map((e) => {
          const anchor = e.trim();
          switch (anchor) {
            case 'インサイト':
              return `<a href="${dir}${addQueryString}">${anchor}</a>`;
            case 'いいね数・リプライ数が多いコメント':
              return `<a href="${dir}${addQueryString}#${anchor}">${anchor}</a>`;
            default:
              return anchor;
          }
        });
      } else if (pageString.indexOf('楽曲数値') === 0) {
        const dir = '/artist-music-value';
        reslut = pageString.split('>').map((e) => {
          const anchor = e.trim();
          switch (anchor) {
            case '楽曲数値':
              return `<a href="${dir}${addQueryString}">${anchor}</a>`;
            case '楽曲の推移':
            case 'ストリーミング再生回数':
            case 'YouTube動画視聴回数':
            case 'アーティスト名を含むツイート数':
              return `<a href="${dir}${addQueryString}#${anchor}">${anchor}</a>`;
            default:
              return anchor;
          }
        });
      } else if (pageString.indexOf('メディア') === 0) {
        const dir = '/artist-media';
        reslut = pageString.split('>').map((e) => {
          const anchor = e.trim();
          switch (anchor) {
            case 'メディア':
              return `<a href="${dir}${addQueryString}">${anchor}</a>`;
            case 'メディア接触率':
            case '全体':
            case 'テレビ':
            case 'ラジオ':
            case '雑誌':
            case '新聞':
              return `<a href="${dir}${addQueryString}#${anchor}">${anchor}</a>`;
            default:
              return anchor;
          }
        });
      } else if (pageString.indexOf('ファンクラブ') === 0) {
        const dir = '/fanclub-analytics';
        reslut = pageString.split('>').map((e) => {
          const anchor = e.trim();
          switch (anchor) {
            case 'ファンクラブ':
              return `<a href="${dir}${addQueryString}">${anchor}</a>`;
            case '売れ筋商品':
            case '会員属性・退会者属性':
            case '年代・性別':
            case 'ECサイト商品別詳細':
              return `<a href="${dir}${addQueryString}#${anchor}">${anchor}</a>`;
            default:
              return anchor;
          }
        });
      } else if (pageString.indexOf('一般統計 > 楽曲ランキング') === 0) {
        const dir = '/statistics-music-rank';
        reslut = pageString.split('>').map((e) => {
          const anchor = e.trim();
          switch (anchor) {
            case '楽曲ランキング':
              return `<a href="${dir}">${anchor}</a>`;
            case 'オリコンST TOP50分析':
            case 'UGC TOP50分析':
              return `<a href="${dir}#${anchor}">${anchor}</a>`;
            default:
              return anchor;
          }
        });
      } else if (pageString.indexOf('一般統計 > 若年層 音楽') === 0) {
        const dir = '/statistics-young-music';
        reslut = pageString.split('>').map((e) => {
          const anchor = e.trim();
          switch (anchor) {
            case '若年層 音楽':
              return `<a href="${dir}">${anchor}</a>`;
            case '音楽を聴くシチュエーション':
            case '音楽を聴く時の行動':
            case '音楽を聴くデバイス':
            case '新しいアーティストや曲の発見場所':
            case '気になった曲の探し方':
              return `<a href="${dir}#${anchor}">${anchor}</a>`;
            default:
              return anchor;
          }
        });
      } else if (pageString.indexOf('一般統計 > ライブ会場別デモグラ') === 0) {
        const dir = '/statistics-livevenue';
        reslut = pageString.split('>').map((e) => {
          const anchor = e.trim();
          switch (anchor) {
            case 'ライブ会場別デモグラ':
              return `<a href="${dir}">${anchor}</a>`;
            case '来場者デモグラ':
              return `<a href="${dir}#${anchor}">${anchor}</a>`;
            default:
              return anchor;
          }
        });
      } else if (pageString.indexOf('その他 > レポート一覧') === 0) {
        const dir = '/report-list';
        reslut = pageString.split('>').map((e) => {
          const anchor = e.trim();
          switch (anchor) {
            case 'レポート一覧':
              return `<a href="${dir}">${anchor}</a>`;
            default:
              return anchor;
          }
        });
      }

      return reslut.join(' > ');
    },
  },
  watch: {
    manualFilter: function () {
      this.activeCategory = null;
      this.activeDetail = null;
    },
  },
};
</script>

<style scoped lang="scss">
.manual-wrapper {
  display: flex;
  flex-direction: column;
  .accordion-item {
    margin: 10px 0;
    .accordion-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: var(--text-color);
      border: 1px solid var(--gray-03-color);
      border-radius: 2px;
      box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: 0.5s;
      &:hover {
        background-color: var(--gray-05-color);
      }
      h4 {
        color: var(--text-color);
        font-size: 16px;
        font-weight: normal;
        padding: 8px 16px;
        text-align: left;
      }
    }
    .accordion__content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin: 0 1px;
      background-color: white;
      border: 1px solid var(--gray-03-color);
      border-top: none;
      border-radius: 0 0 2px 2px;
      box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.1);
      padding: 16px 32px 8px;
      .link-info {
        margin-left: 8px;
      }
      .link {
        font-size: 16px;
        font-weight: bold;
        margin: 8px 0;
        ::v-deep(a) {
          text-decoration: underline;
        }
      }
      .how_to {
        margin: 16px 0;
        text-decoration: var(--group02-2-color) wavy underline;
      }
      img {
        margin-top: 24px;
        margin-inline: auto;
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.3);
      }
      // }
      .info {
        margin: 24px 32px 24px;
        a {
          text-decoration: underline;
        }
      }
    }

    .icon-arrow_drop_down {
      color: var(--text-color);
      font-size: 32px;
      transition: transform 0.3s ease;
      &.active {
        transform: translateY(2px) rotate(180deg);
      }
    }
  }
}
</style>
