import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

export default {
  inject: {
    userAttribute: {
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    /////////////////////////////////
    // Logout
    async $logout() {
      if (this.userAttribute.authProvider === 'azuread') {
        await this.$msal.instance.logoutPopup({
          mainWindowRedirectUri: '/',
        });
      } else {
        const poolData = {
          UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
          ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
        };
        const userPool = new CognitoUserPool(poolData);
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser !== null) {
          cognitoUser.signOut();
          location.href = '/';
        }
      }
      this.userAttribute.email = '';
      this.userAttribute.authProvider = '';
      this.userAttribute.jwtToken = '';
      this.userAttribute.exp = 0;
    },

    /////////////////////////////////
    // Azure AD へのログイン
    async $azureAdLogin() {
      await this.$msal.instance
        .loginPopup({ scopes: ['openid'] })
        .then(() => {
          this.$router.go({ path: this.$router.currentRoute.path, force: true });
        })
        .catch((error) => {
          console.error(`error during authentication: ${error}`);
        });
    },

    // Cognito へのログイン
    $cognitoLogin(username, password) {
      //cognito設定
      const poolData = {
        UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
        ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
      };
      const userPool = new CognitoUserPool(poolData);
      const authenticationData = {
        Username: username,
        Password: password,
      };
      const authenticationDetails = new AuthenticationDetails(authenticationData);
      const userData = {
        Username: authenticationData.Username,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);

      //ログイン処理
      return new Promise(function (res) {
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: () => {
            res('onSuccess');
          },
          onFailure: (err) => {
            if (err.message === 'Incorrect username or password.') res('Incorrect');
            res(err.message || JSON.stringify(err));
          },
          newPasswordRequired: () => {
            res('newPasswordRequired');
          },
        });
      });
    },

    /////////////////////////////////
    // トークンの取得 Azure AD
    async getAzureAdToken() {
      try {
        const accounts = await this.$msal.instance.getAllAccounts();
        const request = {
          account: accounts[0],
          scopes: ['openid', 'email'],
          forceRefresh: true,
        };

        try {
          const response = await this.$msal.instance.acquireTokenSilent(request);
          return [response.idToken, response.idTokenClaims.exp];
        } catch (error) {
          if (error.name === 'InteractionRequiredAuthError') {
            try {
              const response = await this.$msal.instance.acquireTokenPopup(request);
              return [response.idToken, response.idTokenClaims.exp];
            } catch (error) {
              // Acquire token interactive failure
              console.error(error);
              return ['', 0];
            }
          } else {
            console.error(error);
            return ['', 0];
          }
        }
      } catch (error) {
        console.error(error);
        return ['', 0];
      }
    },

    async refreshToken() {
      this.userAttribute.jwtToken = '';
      if (this.userAttribute.authProvider === 'azuread') {
        [this.userAttribute.jwtToken, this.userAttribute.exp] = await this.getAzureAdToken();
      } else if (this.userAttribute.authProvider === 'cognito') {
        await this.getUserAttributeCognito();
      }
      return !!this.userAttribute.jwtToken;
    },

    async $getToken() {
      const TOKEN_EXPIRY_THRESHOLD = 5 * 60 * 1000; // 5 minutes in milliseconds
      let diffTime = new Date(this.userAttribute.exp * 1000) - new Date();
      if (this.userAttribute.jwtToken.length === 0 || diffTime < TOKEN_EXPIRY_THRESHOLD) {
        return await this.refreshToken();
      } else {
        return true;
      }
    },

    /////////////////////////////////
    // ユーザー情報の取得 Azure AD
    async getUserAttributeAzureAd() {
      const account = this.$msal.accounts[0];
      if (account?.username === undefined) {
        return false;
      }
      this.userAttribute.email = account.username;
      this.userAttribute.authProvider = 'azuread';
      // this.userAttribute.jwtToken = account.idToken;
      // this.userAttribute.exp = account.idTokenClaims.exp;
      [this.userAttribute.jwtToken, this.userAttribute.exp] = await this.getAzureAdToken();
      return account.idToken;
    },
    // ユーザー情報の取得 Cognito
    async getUserAttributeCognito() {
      try {
        const poolData = {
          UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
          ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
        };
        const userPool = new CognitoUserPool(poolData);
        const cognitoUser = userPool.getCurrentUser();
        if (!cognitoUser) {
          return false; // ユーザーがログインしていない場合
        }

        const session = await new Promise((resolve, reject) => {
          cognitoUser.getSession((err, session) => {
            if (err) {
              console.error(err.message || JSON.stringify(err));
              reject(err);
            } else {
              resolve(session);
            }
          });
        });
        const userAttributes = await new Promise((resolve, reject) => {
          cognitoUser.getUserAttributes((err, attributes) => {
            if (err) {
              console.error(err.message || JSON.stringify(err));
              reject(err);
            } else {
              resolve(attributes);
            }
          });
        });

        const emailAttribute = userAttributes.find((attr) => attr.getName() === 'email');
        const email = emailAttribute ? emailAttribute.getValue() : '';

        this.userAttribute.email = email;
        this.userAttribute.authProvider = 'cognito';
        this.userAttribute.jwtToken = session.idToken.jwtToken;
        this.userAttribute.exp = session.idToken.payload.exp;

        return email;
      } catch (error) {
        console.error(error.message || JSON.stringify(error));
        return false;
      }
    },
    async $getUserAttribute() {
      let email = await this.getUserAttributeAzureAd();
      if (!email) email = await this.getUserAttributeCognito();
      return email;
    },
  },
};
