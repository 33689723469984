<template>
  <div class="ArtistList">
    <div class="header-line">
      <h3 class="title">一覧</h3>
      <span class="icon icon-cross" @click="$emit('closeOverlayView')"></span>
    </div>
    <div class="contents">
      <ul class="row-tab shadow">
        <li
          class="tab-item unselectable"
          v-for="label in contentsTab"
          :key="'contentsTab' + label"
          :class="{ active: activeContentsTab === label }"
          @click="clickContentsTab(label)"
        >
          {{ label }}
        </li>
      </ul>

      <div class="tab-contents shadow">
        <template v-if="activeContentsTab === 'アーティスト'">
          <ul class="yomi-tabs">
            <li
              class="yomi-tabs-item unselectable"
              v-for="label in yomiFirstCharacter"
              :key="'yomiFirstCharacter' + label"
              :class="{ active: activeTabYomi === label }"
              @click="clickYomiFirstCharacter(label)"
            >
              <span>{{ label }}</span>
            </li>
          </ul>
          <div class="artist-name-area" v-if="activeTabYomi === 'ALL'">
            <template v-for="label in 10" :key="label">
              <div class="section-title" v-if="pageArtist(yomiFirstCharacter[label]).length">{{ yomiFirstCharacter[label] }}</div>
              <div class="artist-list" v-if="pageArtist(yomiFirstCharacter[label]).length">
                <a class="artist-item" @click.self="pageMove(artistInfo)" v-for="artistInfo in pageArtist(yomiFirstCharacter[label])" :key="artistInfo">
                  {{ artistInfo.artist_name }}
                  <i class="icon icon-person_add_alt_1" @click="updateArtistFavorite(artistInfo)" v-if="!artistInfo.isfavorite"></i>
                  <i class="icon icon-person favorite" @click="updateArtistFavorite(artistInfo)" v-if="artistInfo.isfavorite"></i>
                </a>
              </div>
            </template>
          </div>

          <div class="artist-name-area" v-else>
            <div class="section-title">{{ activeTabYomi }}</div>
            <div class="artist-list">
              <a class="artist-item" @click.self="pageMove(artistInfo)" v-for="artistInfo in pageArtist(activeTabYomi)" :key="artistInfo">
                {{ artistInfo.artist_name }}
                <i class="icon icon-person_add_alt_1" @click="updateArtistFavorite(artistInfo)" v-if="!artistInfo.isfavorite"></i>
                <i class="icon icon-person favorite" @click="updateArtistFavorite(artistInfo)" v-if="artistInfo.isfavorite"></i>
              </a>
            </div>
          </div>
        </template>

        <div class="artist-name-area" v-if="activeContentsTab !== 'アーティスト'">
          <div class="artist-list">
            <a class="artist-item" @click.self="pageMove(artistInfo)" v-for="artistInfo in contentsList[activeContentsTab]" :key="artistInfo">
              {{ artistInfo.artist_name }}
              <i class="icon icon-person_add_alt_1" @click="updateArtistFavorite(artistInfo)" v-if="!artistInfo.isfavorite"></i>
              <i class="icon icon-person favorite" @click="updateArtistFavorite(artistInfo)" v-if="artistInfo.isfavorite"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArtistList',
  props: ['artistListPage'],
  data() {
    return {
      activeContentsTab: '',
      contentsTabOrder: ['アーティスト', 'アニメ・ゲーム', 'サイト', 'ソニーグループ'],

      yomiFirstCharacter: ['ALL', 'ア', 'カ', 'サ', 'タ', 'ナ', 'ハ', 'マ', 'ヤ', 'ラ', 'ワ'],
      activeTabYomi: 'ALL',
      pageArtistCount: 40,
      contentsList: {},
    };
  },
  computed: {
    contentsTab() {
      let contentsTab = [];
      if (this.artistListPage.length) {
        const contentsType = [...new Set(this.artistListPage.map((e) => (e.type === 'アニメ' || e.type === 'ゲーム' ? 'アニメ・ゲーム' : e.type)))];
        contentsTab = this.contentsTabOrder.filter((order) => contentsType.includes(order));
        this.createContentsList(contentsTab);
        if (contentsTab.length === 1) {
          contentsTab = [];
        }
      }
      return contentsTab;
    },
  },
  methods: {
    createContentsList(contentsTab) {
      contentsTab.forEach((e) => {
        this.contentsList[e] = this.artistListPage.filter((d) => (d.type === 'アニメ' || d.type === 'ゲーム' ? 'アニメ・ゲーム' === e : d.type === e));
      });
      if (this.activeContentsTab === '') this.activeContentsTab = contentsTab[0];
    },

    pageMove(artistInfo) {
      this.$emit('pageMove', artistInfo);
    },
    updateArtistFavorite(artistInfo) {
      this.$emit('updateArtistFavorite', artistInfo.artist_code);
    },
    pageArtist(firstCharacter) {
      if (this.contentsList.アーティスト?.length) {
        let artistlist = this.contentsList.アーティスト;
        artistlist = artistlist.filter((e) => this.checkGojyuon(e, firstCharacter));
        return artistlist.slice(0, this.pageArtistCount);
      }
      return [];
    },
    checkGojyuon(e, firstCharacter) {
      let ret;
      const atama = e.artist_name_kana.charAt(0);
      if (firstCharacter === 'ALL') {
        ret = atama.match(/./);
      } else if (firstCharacter === 'ア') {
        ret = atama.match(/[ァ-オ|ヴ]/);
      } else if (firstCharacter === 'カ') {
        ret = atama.match(/[カ-ゴ|ヵ|ヶ]/);
      } else if (firstCharacter === 'サ') {
        ret = atama.match(/[サ-ゾ]/);
      } else if (firstCharacter === 'タ') {
        ret = atama.match(/[タ-ド]/);
      } else if (firstCharacter === 'ナ') {
        ret = atama.match(/[ナ-ノ]/);
      } else if (firstCharacter === 'ハ') {
        ret = atama.match(/[ハ-ポ]/);
      } else if (firstCharacter === 'マ') {
        ret = atama.match(/[マ-モ]/);
      } else if (firstCharacter === 'ヤ') {
        ret = atama.match(/[ャ-ヨ]/);
      } else if (firstCharacter === 'ラ') {
        ret = atama.match(/[ラ-ロ]/);
      } else if (firstCharacter === 'ワ') {
        ret = atama.match(/[ヮ-ン|ヷ-ヺ]/);
      }
      return ret;
    },
    clickContentsTab: function (label) {
      this.activeContentsTab = label;
    },
    clickYomiFirstCharacter: function (label) {
      this.activeTabYomi = label;
    },
  },
};
</script>

<style scoped lang="scss">
.ArtistList {
  width: 1280px;
  height: 94%;

  .header-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    width: 100%;
    padding-left: 16px;
    font-size: var(--font-size-middle2);
    font-weight: bold;
    background: var(--primary-color);
    border-radius: 16px 16px 0 0;
    color: white;
    .icon-cross {
      padding: 6px;
      margin-right: 2px;
      cursor: pointer;
    }
  }

  .contents {
    display: block;
    height: inherit;
    padding: 20px;
    padding-top: 32px;
    background-color: var(--background-color);
    border-radius: 0 0 16px 16px;
    .row-tab {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      width: 100%;
      border-radius: 4px 4px 0 0;
      font-size: var(--font-size-middle);
      color: var(--primary-color);
      gap: 8px;
      .tab-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 44px;
        background-color: white;
        border-radius: 12px 12px 0 0;
        font-weight: bold;
        cursor: pointer;
        transition: 0.5s;
        &.active {
          color: white;
          background-color: var(--primary-color);
          cursor: default;
        }
        &:not(.active):hover {
          background-color: var(--group01-1-color);
        }
      }
    }

    .tab-contents {
      height: inherit;
      padding: 16px;
      padding-bottom: 0;
      background-color: var(--gray-04-color);

      .yomi-tabs {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        width: fit-content;
        font-size: var(--font-size-middle);
        color: var(--primary-color);
        .yomi-tabs-item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 2em;
          font-weight: bold;
          cursor: pointer;
          transition: 0.5s;
          &.active {
            color: white;
            background-color: var(--primary-color);
            cursor: default;
          }
          &:not(.active):hover {
            background-color: var(--group01-1-color);
          }
          &:not(:first-child):before {
            content: '';
            border: 1px solid var(--primary-color);
            align-self: stretch;
          }
          span {
            width: 100%;
            display: inline-block;
            text-align: center;
          }
        }
      }

      .artist-name-area {
        height: inherit;
        overflow-y: auto;
        .section-title {
          display: flex;
          align-items: center;
          height: 40px;
          width: 100%;
          margin: 16px 0;
          padding-left: 16px;
          font-size: var(--font-size-middle2);
          font-weight: bold;
          background: var(--primary-color);
          border-radius: 3px;
          color: white;
          &:not(:first-child) {
            margin-top: 40px;
          }
        }

        .artist-list {
          display: flex;
          flex-wrap: wrap;
          gap: 18px 12px;
          font-weight: bold;
          margin-bottom: 16px;
          padding: 0 20px;
          .artist-item {
            display: flex;
            align-items: center;
            height: 32px;
            padding: 4px 16px;
            padding-right: 4px;
            color: var(--primary-color);
            background-color: white;
            border: 2px solid var(--primary-color);
            border-radius: 16px;
            .icon {
              display: grid;
              place-content: center;
              height: 22px;
              width: 32px;
              text-align: center;
              margin-left: 16px;
              font-size: 16px;
              color: var(--primary-color);
              background-color: white;
              border: 1.4px solid var(--primary-color);
              border-radius: 11px;
              &.favorite {
                color: white;
                background-color: var(--primary-color);
              }
            }
            transition: background-color 0.3s ease;
            &:hover {
              background-color: var(--group01-1-color);
            }
          }
        }
      }
    }
  }
}
</style>
