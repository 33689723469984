<template>
  <footer class="footer">
    <div class="logo">
      <a href="/">
        <img class="logo-image" src="/images/logo2.webp" loading="lazy" decoding="async" fetchpriority="low" />
      </a>
    </div>
    <div class="copyright">&copy; Sony Music Entertainment (Japan) Inc.</div>
  </footer>
</template>

<script>
export default {
  name: 'FooterBar',
  data() {
    return {};
  },
  async created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: var(--footer-bar-height);
  min-height: var(--footer-bar-height);
  background-color: var(--gray-04-color);
  .logo img {
    width: 198px;
    height: 38px;
  }
  .copyright {
    font-size: 1.2rem;
    margin-top: 16px;
  }
}
</style>
