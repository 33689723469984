export default {
  inject: {
    userAttribute: {
      default: () => ({}),
    },
    artistAndTitle: {
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    $writeAccessLog: async function (scope = '') {
      if (!(await this.$getToken())) {
        return;
      }
      try {
        //パラメーター変数名
        const loc = document.location;
        const params = {
          href: loc.href,
          pathname: loc.pathname,
          query: loc.search,
          hash: loc.hash,
          referrer: document.referrer,
          artist: scope ? (scope === 'source' || scope === 'target' ? this.artistAndTitle[scope].artistObj.artist_name : scope) : '',
        };
        this.$fetchPost('/common/access-log-write', JSON.stringify(params));
      } catch (e) {
        console.error(e);
      }
    },

    //////////////////
    // fetch
    //////////////////

    errorHandler: async function (response) {
      console.warn('fetch, errorHandler, response.status: ' + response.status);
      if (response.status === 401) {
        const jsonResponse = await response.json();
        if (jsonResponse.is_password_expired) {
          this.userAttribute.isPasswordExpired = true;
        } else {
          alert('エラーが発生しました。ページをリロードして下さい。');
        }
      } else if (response.status === 403) {
        alert('閲覧権限がありません。');
        if (location.pathname === '/') this.$logout();
        else this.$router.push('/');
      } else if (response.status >= 400 && response.status < 500) {
        alert('再ログインをお願いします。code:' + response.status);
        this.$logout();
      } else if (response.status === 503) {
        // alert('API 通信でタイムアウトが発生しました。code:' + response.status);
      } else if (response.status >= 500 && response.status < 600) {
        alert('サーバーでエラーが発生しました。code:' + response.status);
      } else {
        alert('エラーが発生しました。code:' + response.status);
      }
    },

    flattenObject(ob, prefix = '') {
      let result = {};
      for (const i in ob) {
        if (typeof ob[i] === 'object' && ob[i] !== null) {
          Object.assign(result, this.flattenObject(ob[i], prefix + i + '.'));
        } else {
          result[prefix + i] = ob[i];
        }
      }
      return result;
    },

    async $fetch(api, query = {}) {
      try {
        let urlQuery = this.userAttribute.authProvider === 'azuread' ? api : api + '-c';
        const loc = document.location;
        const baseQuery = {
          href: loc.href,
          pathname: loc.pathname,
          query: loc.search,
          hash: loc.hash,
          referrer: document.referrer,
        };
        const flattenedQuery = this.flattenObject(query);
        urlQuery += '?' + new URLSearchParams(Object.assign({}, baseQuery, flattenedQuery));

        const response = await fetch(process.env.VUE_APP_API_ENDPOINT + urlQuery, {
          method: 'GET',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.userAttribute.jwtToken,
          },
        });
        if (response.ok) {
          const responseBody = await response.text();
          try {
            return JSON.parse(responseBody);
          } catch (error) {
            return responseBody;
          }
        }
        this.errorHandler(response);
        throw new Error(response.status);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async $fetchDelete(api, query = {}) {
      let urlQuery = this.userAttribute.authProvider === 'azuread' ? api : api + '-c';
      if (Object.keys(query).length) {
        urlQuery += '?' + new URLSearchParams(query);
      }

      try {
        const response = await fetch(process.env.VUE_APP_API_ENDPOINT + urlQuery, {
          method: 'DELETE',
          cache: 'no-cache',
          headers: {
            Authorization: 'Bearer ' + this.userAttribute.jwtToken,
          },
        });

        if (response.ok) {
          const responseBody = await response.text();
          try {
            return JSON.parse(responseBody);
          } catch (error) {
            return responseBody;
          }
        }
        this.errorHandler(response);
        throw new Error(response.status);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async $fetchPost(api, data) {
      const urlQuery = this.userAttribute.authProvider === 'azuread' ? api : api + '-c';
      try {
        const response = await fetch(process.env.VUE_APP_API_ENDPOINT + urlQuery, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.userAttribute.jwtToken,
          },
          body: data,
        });
        if (response.ok) {
          const responseBody = await response.text();
          try {
            return JSON.parse(responseBody);
          } catch (error) {
            return responseBody;
          }
        }
        this.errorHandler(response);
        throw new Error(response.status);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};
